<template>
  <FormWizard
    :url="'send_email.php'"
    :buttonSubmit="messages[locale].contact_form.buttonSubmit"
  >
    <BaseFormStep>
      <BaseInput
        :label="$t('contact_form.fullname')"
        name="userName"
        placeholder=" "
      />
      <BaseInput
        :label="$t('contact_form.email')"
        name="userEmail"
        :lazy="true"
        placeholder=" "
      />
      <BaseInput
        :label="$t('contact_form.company')"
        name="company"
        :lazy="true"
        placeholder=" "
      />
      <BaseInput
        :label="$t('contact_form.yourMsg')"
        name="content"
        :rows="3"
        placeholder=" "
      />
    </BaseFormStep>
  </FormWizard>
</template>

<script setup>
import { useI18n } from "vue-i18n";
import FormWizard from "./wizard/BaseFormWizard.vue";

const { messages, locale } = useI18n();
</script>

<style lang="scss" scoped></style>
