<template>
  <FullpageSection class="trusted-by-slide" :title="$t('slide_trusted_by.title')">
    <div
      class="content content-span-sm-8 content-start-md-2 content-span-md-5 row-md-4 var-pl"
    >
      <div class="separator bg-yellow"></div>
    </div>
    <div
      class="trusted-by-prev swiper-button button-prev full row-4 display-md justify-self-start align-self-center z-mid"
    >
      <div class="flex justify-center items-center">
        <img src="@/assets/icons/arrow.svg" alt="" />
      </div>
    </div>
    <swiper-container
      class="content content-span-md-11 row-md-4 align-self-center w-full var-padding"
      :pagination="{
        el: '.trusted-by-pagination',
      }"
      :navigation="{
        nextEl: '.trusted-by-next',
        prevEl: '.trusted-by-prev',
      }"
      :loop="true"
      :looped-slides="2"
      :space-between="20"
      :autoplay="{
        delay: 5000,
      }"
      :init="false"
      ref="swiper"
    >
      <swiper-slide
        v-for="(slide, index) in messages[locale].slide_trusted_by.slides"
        :key="index"
      >
        <div class="card flex flex-row-md gap-gap white w-full h-full">
          <p class="quote-block text-flash pb-5">
            {{ slide.quote }}
          </p>
          <div class="quotee flex flex-column">
            <VuePicture v-if="slide.img" :img="slide.img" class="mb-3" />
            <b v-if="slide.name">{{ slide.name }}</b>
            <span v-if="slide.company_position" class="mb-2">{{
              slide.company_position
            }}</span>
            <span v-if="slide.company_name">{{ slide.company_name }}</span>
          </div>
        </div>
      </swiper-slide>
    </swiper-container>
    <div
      class="trusted-by-next swiper-button button-next full row-4 display-md justify-self-end align-self-center z-mid"
    >
      <div class="flex justify-center items-center">
        <img src="@/assets/icons/arrow.svg" alt="" />
      </div>
    </div>
    <div
      class="trusted-by-pagination swiper-pagination content row-md-3 justify-self-end align-self-end flex justify-center gap-3"
    ></div>
  </FullpageSection>
</template>

<script setup>
import VuePicture from "@/components/VuePicture.vue";
import { useI18n } from "vue-i18n";
import { ref, onMounted } from "vue";

const { messages, locale } = useI18n();
const swiper = ref();

onMounted(() => {
  swiper.value.initialize();
  /*   swiper.value.addEventListener("init", () => {
    swiper.value.autoplay.start();
  });
  swiper.value.addEventListener("resize", () => {
    swiper.value.autoplay.start();
  }); */
});
</script>

<style lang="scss" scoped>
.trusted-by-pagination {
  @include breakpoint-down(md) {
    position: static;
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
  }
}
.quotee {
  @include breakpoint-up(md) {
    flex: 1 0 24.6%;
  }
}

.quote-block {
  padding-top: 1rem;
  &::after,
  &::before {
    content: "";
    background: url("@/assets/icons/quote_marks.svg") no-repeat;
    background-size: contain;
    display: inline-block;
    height: 30px;
    width: 40px;
  }
  &::before {
    margin-right: 0.25rem;
  }
  &::after {
    transform: rotate(180deg);
    margin-left: 0.25rem;
    margin-bottom: -1rem;
  }

  @include breakpoint-up(md) {
    padding-top: 0;
    display: flex;
    &::before,
    &::after {
      min-width: calc(var(--hidden-width) + var(--gap));
      height: calc((var(--hidden-width) + var(--gap)) * 3 / 4);
      margin-right: calc(var(--hidden-width) / 2);
    }

    &::after {
      margin-left: 1rem;
      margin-bottom: 0;
    }
  }
}
</style>
