<template>
  <FullpageSection class="advantages-slide bg-white" :title="$t('slide_advantages.title')">
    <div class="content content-span-sm-8 content-start-md-2 content-span-md-5 row-md-4 var-pl">
      <div class="separator bg-yellow"></div>
      <p class="blue-dark var-padding pb-8 text-flash">
        <span class="h1 block blue-medium pb-4">{{ $t('slide_advantages.flash.title') }}</span>
        {{ $t('slide_advantages.flash.body') }}
      </p>
    </div>
    <TabContent class="content content-start-md-7 content-span-md-6 row-md-4 mb-5" :tabs="messages[locale].slide_advantages.tabs" />
  </FullpageSection>
</template>

<script setup>
import TabContent from '@/components/TabContent.vue';
import { useI18n } from 'vue-i18n';
const { messages, locale} = useI18n()
</script>

<style lang="scss" scoped>

</style>