export default {
  title: 'Storitve',
  flash: {
    title: 'Vrhunska ponudba za vse deležnike',
    body: 'Naše celovite storitve upravljanja nepremičnin so namenjene lastnikom poslovnih objektov in investitorjem. Pri svojem delu uporabljamo sodobni inženiring z naprednimi IT rešitvami, ki izboljšujejo učinkovitost upravljanja portfelja.'
  },
  card_1: {
    img: require('@/assets/icons/building-icon.svg'),
    title: 'Za lastnike nepremičnin',
    body: 'Za lastnike nepremičnin v celoti prevzamemo upravljanje nepremičninskega portfelja, ki vključuje tudi redno vzdrževanje nepremičnin.',
    /* list: [
      'Upravljanje porfelja',
      'Upravljanje nepremičnin',
      'Vzdrževanje nepremičnin'
    ] */
  },
  card_2: {
    img: require('@/assets/icons/building-icon.svg'),
    title: 'Za investitorje',
    body: 'V fazi načrtovanja nepremičninskega projekta lahko s strokovnim znanjem in izkušnjami pripomoremo k učinkovitejšemu upravljanju nepremičnin že pred, med in po izgradnji.'
    /* list: [
      'Vzdrževanje',
      'Čiščenje',
      'Inženiring',
      'Druge storitve'
    ] */
  },
}