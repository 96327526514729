<template>
  <div class="background bg-blue-gradient-dark z--1"></div>
  <VueHeader @scrollToTop="scrollToTop" :bgWhite="bgWhite" />
  <main id="fullpage">
    <HomeSlide />
    <DescrptionSlide :visited="visited" />
    <AdvantagesSlide />
    <RealEstateSlide />
    <ServicesSlide />
    <PillarsSlide />
    <ForYouSlide />
    <TrustedBySlide />
    <ReferencesSlide />
    <ContactUsSlide />
  </main>
  <div class="container next-slide-container items-center w-full display-md">
    <div
      id="next-slide"
      :class="['content', { 'alt-color': altColor }]"
      ref="nextSlideBtn"
      @click="slide"
      :style="lastSlide ? 'transform: rotateX(180deg)' : null"
    >
      <svg
        width="28"
        height="53"
        viewBox="0 0 28 53"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.2227 36.8415L14 42.2829L8.77734 36.8415L7.71094 37.8806L14 44.4431L20.2891 37.8806L19.2227 36.8415ZM13.2617 24.6188H14.7383V26.0954H13.2617V24.6188ZM13.2617 27.5993H14.7383V29.1032H13.2617V27.5993ZM13.2617 30.6071H14.7383V32.111H13.2617V30.6071ZM13.2617 33.6149H14.7383V35.1188H13.2617V33.6149ZM13.2617 36.5954H14.7383V38.0993H13.2617V36.5954ZM13.2617 39.6032H14.7383V41.1071H13.2617V39.6032Z"
          :fill="altColor ? '#005188' : '#BECDE2'"
        />
      </svg>
    </div>
  </div>
</template>

<script setup>
/* Other imports */
import Fullpage from "fullpage.js";
import "fullpage.js/dist/fullpage.css";
/* Vue specific */
import VueHeader from "@/partials/layout/VueHeader.vue";
import { onMounted, ref } from "vue";
import { onBeforeRouteLeave } from "vue-router";

import HomeSlide from "@/partials/slides/HomeSlide.vue";
import DescrptionSlide from "@/partials/slides/DescriptionSlide.vue";
import RealEstateSlide from "@/partials/slides/RealEstateSlide.vue";
import ServicesSlide from "@/partials/slides/ServicesSlide.vue";
import PillarsSlide from "@/partials/slides/PillarsSlide.vue";
import AdvantagesSlide from "@/partials/slides/AdvantagesSlide.vue";
import ForYouSlide from "@/partials/slides/ForYouSlide.vue";
import TrustedBySlide from "@/partials/slides/TrustedBySlide.vue";
import ReferencesSlide from "@/partials/slides/ReferencesSlide.vue";
import ContactUsSlide from "@/partials/slides/ContactUsSlide.vue";

const props = defineProps({
  breakpoint: {
    type: Number,
    default: 768,
  },
});

const fullpage = ref();
const nextSlideBtn = ref();
const rendered = ref(false);
const lastSlide = ref(false);
const altColor = ref(false);
const bgWhite = ref(false);
const visited = ref(false);

const slide = function () {
  lastSlide.value ? fullpage.value.moveSectionUp() : fullpage.value.moveSectionDown();
};

const scrollToTop = function () {
  if (window.matchMedia(`(min-width: ${props.breakpoint}px)`).matches) {
    fullpage.value.moveTo(1);
  }
};

onMounted(() => {
  fullpage.value = new Fullpage("#fullpage", {
    licenseKey: "1A651525-B7CB40DA-87A4A558-C19C1AC7",

    sectionSelector: ".fp-section",

    scrollingSpeed: 1000,
    touchSensitivity: 10,
    scrollOverflow: false,

    // navigation: true,
    // navigationPosition: 'right',
    // normalScrollElements: '.hide-scrollbar',

    responsiveWidth: props.breakpoint,
    // responsiveHeight: 0,
    verticalCentered: false,

    onLeave: function (origin, destination, direction) {
      if (window.matchMedia(`(min-width: ${props.breakpoint}px)`).matches) {
        if (destination.item.classList.contains("description-slide")) {
          visited.value = true;
        }
        if (destination.isLast) {
          setTimeout(() => {
            lastSlide.value = true;
          }, 200);
        }
        if (origin.isLast) {
          setTimeout(() => {
            lastSlide.value = false;
          }, 500);
        }
        /* if( origin.isFirst ) {
          setTimeout(() => {
            firstSlide.value = false
          }, 200)
        }
        if( destination.isFirst ) {
          setTimeout(() => {
            firstSlide.value = true
          }, 350)
        } */
        if (
          destination.item.classList.contains("bg-white") &&
          !origin.item.classList.contains("bg-white")
        ) {
          if (direction === "down") {
            setTimeout(() => {
              altColor.value = true;
            }, 120);
            setTimeout(() => {
              bgWhite.value = true;
            }, 820);
          }
          if (direction === "up") {
            setTimeout(() => {
              altColor.value = true;
            }, 660);
            setTimeout(() => {
              bgWhite.value = true;
            }, 50);
          }
        }
        if (
          origin.item.classList.contains("bg-white") &&
          !destination.item.classList.contains("bg-white")
        ) {
          if (direction === "down") {
            setTimeout(() => {
              altColor.value = false;
            }, 150);
            setTimeout(() => {
              bgWhite.value = false;
            }, 800);
          }
          if (direction === "up") {
            setTimeout(() => {
              altColor.value = false;
            }, 680);
            setTimeout(() => {
              bgWhite.value = false;
            }, 50);
          }
        }
      }
    },
    afterRender: function () {
      if (props.slide) rendered.value = true;
    },
  });
});

onBeforeRouteLeave((to, from) => {
  fullpage.value.destroy("all");
});
</script>

<style lang="scss">
.background {
  position: fixed;
  height: 100vh;
  width: 100vw;
}
.next-slide-container {
  position: fixed;
  bottom: 0;
  left: 0;
  grid-template-rows: var(--logo-height);
  pointer-events: none;
}

#next-slide {
  pointer-events: all;
  width: 28px;
  height: 100%;
  max-height: 52px;
  border: 1px solid $blue-light;
  border-radius: 24px;
  cursor: pointer;
  z-index: 9999;
  transition: transform 0.2s ease-in;

  &.alt-color {
    border-color: $blue;
  }

  svg {
    position: relative;
    left: -1px;
    top: -1px;
    * {
      transition: transform 0.2s ease-in;
    }
  }
  &:hover {
    svg {
      path {
        transform: translateY(2.5px);
      }
    }
  }

  @include breakpoint-up(md) {
    bottom: 40px;
  }
}
</style>
