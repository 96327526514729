export default {
  title: 'Services',
  flash: {
    title: 'Exquisite offer for all stakeholders',
    body: 'Our comprehensive real estate management services are intended for owners of commercial buildings and investors. At our work, we use modern engineering with advanced IT solutions that improve the efficiency of portfolio management.'
  },
  card_1: {
    img: require('@/assets/icons/building-icon.svg'),
    title: 'For property owners',
    body: 'For property owners, we take over the property portfolio management which includes regular property maintenance.',
    /* list: [
      'Upravljanje porfelja',
      'Upravljanje nepremičnin',
      'Vzdrževanje nepremičnin'
    ] */
  },
  card_2: {
    img: require('@/assets/icons/building-icon.svg'),
    title: 'For investors',
    body: 'In the planning phase of a property project, we contribute to more efficient property management prior to, during and after the construction by using our expertise and experience.'
    /* list: [
      'Vzdrževanje',
      'Čiščenje',
      'Inženiring',
      'Druge storitve'
    ] */
  },
}