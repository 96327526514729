<template>
  <div>
    <!-- Dialog box -->
    <Transition name="fade">
      <BaseDialogBox
        v-if="states.dialog"
        @close-dialog="resetStates()"
        :success="states.success"
        :error="states.error"
      />
    </Transition>
    <form @submit="onSubmit">
      <!-- Spinner loader -->
      <div v-show="states.loading" class="loading-animation">
        <BaseSpinnerCircle width="50px" />
      </div>
      <slot />

      <div class="btn-holder">
        <button
          v-if="hasPrevious"
          type="button"
          @click="goToPrev"
          :disabled="states.loading"
        >
          {{ props.buttonPrev }}
        </button>
        <button
          class="btn-primary justify-center white"
          type="submit"
          :disabled="states.loading"
        >
          <span>{{ isLastStep ? props.buttonSubmit : props.buttonNext }}</span>
        </button>
      </div>
    </form>
  </div>
</template>

<script setup>
/**
 * Import the schema you wish to use, keep "as validationSchema"
 */
import * as yup from "yup";
import { useForm } from "vee-validate";
import { ref, reactive, computed, provide, toRef, onUpdated } from "vue";
import axios from "axios";
import { useI18n } from "vue-i18n";

const { t, locale } = useI18n();
const props = defineProps({
  url: {
    typeof: String,
    require: true,
  },
  buttonNext: {
    type: String,
    default: "Naslednji korak",
  },
  buttonPrev: {
    type: String,
    default: "Prejšni korak",
  },
  buttonSubmit: {
    type: String,
    default: "Pošlji",
  },
});

let formData = new FormData();
const fauxFormData = ref({});
const currentStepIdx = ref(0);

const schema = ref([
  yup.object({
    userName: yup.string().required(t("errorMessages.errorMessage")),
    userEmail: yup
      .string()
      .required(t("errorMessages.errorMessage"))
      .email(t("errorMessages.errorEmail")),
    content: yup.string(),
    page_language: yup.string(),
  }),
]);

onUpdated(() => {
  schema.value = [
    yup.object({
      userName: yup.string().required(t("errorMessages.errorMessage")),
      userEmail: yup
        .string()
        .required(t("errorMessages.errorMessage"))
        .email(t("errorMessages.errorEmail")),
      content: yup.string(),
      page_language: yup.string(),
    }),
  ];
});

/**
 * READ ONlY
 * States and reset states
 */

const states = reactive({
  success: false,
  error: false,
  loading: false,
  dialog: computed(() => {
    if (states.success || states.error) return true;
    else return false;
  }),
});

const resetStates = function () {
  states.success = false;
  states.error = false;
};

// Injects the starting step, child <form-steps> will use this to generate their ids
const stepCounter = ref(0);
provide("STEP_COUNTER", stepCounter);

// Inject the live ref of the current index to child components
// will be used to toggle each form-step visibility
provide("CURRENT_STEP_INDEX", currentStepIdx);
provide("FORM_DATA", formData);

const isLastStep = computed(() => {
  return currentStepIdx.value === stepCounter.value - 1;
});

const hasPrevious = computed(() => {
  return currentStepIdx.value > 0;
});

const currentSchema = computed(() => {
  return schema.value[currentStepIdx.value];
});

// vee-validate will be aware of computed schema changes
const { resetForm, handleSubmit } = useForm({
  validationSchema: currentSchema,
});

// We are using the "submit" handler to progress to next steps
// and to submit the form if its the last step

const onSubmit = handleSubmit((values, { resetForm }) => {
  resetStates();
  states.loading = true;
  formData.set("page_language", locale.value);

  /**
   * AXIOS CALL HERE
   */
  axios
    .post(props.url, formData)
    .then((response) => {
      resetForm();
      states.success = true;
      /* for (const key of formData.key()) {
        formData.delete(key);
      } */
    })
    .catch((error) => {
      states.error = true;
    })
    .finally(() => {
      states.loading = false;
    });
});

const goToPrev = function () {
  if (currentStepIdx.value === 0) {
    return;
  }
  currentStepIdx.value--;
  resetForm({
    values: {
      ...fauxFormData.value,
    },
  });
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
