import { createRouter, createWebHistory } from "vue-router";
import Landing from "@/views/Landing.vue";
import i18n from "@/lang/i18n";

let locale = i18n.global.locale.value

const routes = [
  {
    path: "/",
    redirect: `/${locale}`
  },
  {
    path: "/:lang",
    name: "Landing",
    component: Landing,
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const lang = to.params.lang;

  if(!i18n.global.availableLocales.includes(lang)) next(i18n.global.fallbackLocale.value);
  if(i18n.global.locale.value != lang) i18n.global.locale.value = lang
  next();
})

export default router;