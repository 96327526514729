<template>
  <FullpageSection :title="$t('slide_real_estate.title')" class="real-estate-slide">
    <div
      class="real-estate-prev swiper-button button-prev full row-4 display-md justify-self-start align-self-center z-mid"
    >
      <div class="flex justify-center items-center">
        <img src="@/assets/icons/arrow.svg" alt="" />
      </div>
    </div>
    <swiper-container
      class="full content-start-md-1 row-md-4 w-full h-full"
      :pagination="{
        el: '.real-estate-pagination',
      }"
      :navigation="{
        nextEl: '.real-estate-next',
        prevEl: '.real-estate-prev',
      }"
      :loop="true"
      :looped-slides="2"
      :space-between="20"
      :autoplay="{
        delay: 5000,
      }"
      :init="false"
      ref="swiper"
    >
      <swiper-slide
        v-for="(slide, index) in messages[locale].slide_real_estate.slides"
        :key="index"
      >
        <div class="card flex flex-row-md w-full h-full">
          <VuePicture :img="slide.img" class="full cover col-100" />
          <div class="info flex flex-column white bg-blue-darkest px-5 pt-4 pb-9">
            <b>{{ slide.property_name }}</b>
            <span v-html="slide.location"></span>
            <div class="border-top pt-3 mt-3">
              <span>{{ slide.years }}</span>
              <ul class="pt-4">
                <li v-for="(feature, index) in slide.features" :key="index">
                  {{ feature }}
                </li>
              </ul>
            </div>
            <!-- <span class="gray-lines mt-auto pt-6">Partner</span>
            <span>BTC d.d.</span> -->
          </div>
        </div>
      </swiper-slide>
    </swiper-container>
    <div
      class="real-estate-next swiper-button button-next full row-4 display-md justify-self-end align-self-center z-mid"
    >
      <div class="flex justify-center items-center">
        <img src="@/assets/icons/arrow.svg" alt="" />
      </div>
    </div>
    <div
      class="real-estate-pagination swiper-pagination content row-md-3 justify-self-end align-self-end flex justify-center gap-3"
    ></div>
  </FullpageSection>
</template>

<script setup>
import { useI18n } from "vue-i18n";
import VuePicture from "@/components/VuePicture.vue";
import { ref, onMounted } from "vue";

const { messages, locale } = useI18n();
const swiper = ref();

onMounted(() => {
  swiper.value.initialize();
});
</script>

<style lang="scss" scoped>
swiper-container {
  overflow: visible;

  @include breakpoint-up(md) {
    grid-column-end: span 23;
  }
}
.card {
  padding: 1px;
}

.info {
  @include breakpoint-up(md) {
    padding: 1rem 1.5rem 1.75rem;
    flex: 0 1 30%;
  }
}
.border-top {
  border-top: 1px solid $black;
}
</style>
