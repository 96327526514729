<template>
  <div class="tabs-holder flex flex-column">
    <div class="tabs relative mb-6">
      <div class="absolute gradient gradient-left" ref="gradientLeft"></div>
      <swiper-container
        ref="swiper"
        class="w-full relative"
        :long-swipes="false"
        :slides-per-view="'auto'"
        :space-between="28"
        :init="false"
      >
        <swiper-slide
          ref="slide"
          v-for="(tab, index) in tabs"
          :key="index"
          :class="['relative', { active: currentIndex === index }]"
          @click="toggleActive(index)"
        >
          <span class="text-tab uppercase">
            {{ tab.title }}
          </span>
        </swiper-slide>
        <div ref="slider" class="slider"></div>
      </swiper-container>
      <div class="absolute gradient gradient-right" ref="gradientRight"></div>
    </div>
    <div class="content col-100">
      <template v-for="(tab, index) in tabs">
        <div v-if="currentIndex === index" class="flex flex-column h-full">
          <p v-if="tab.content.intro" class="intro text-flash blue-dark">
            <b>{{ tab.content.intro }}</b>
          </p>
          <div v-if="tab.content.body" v-html="tab.content.body" class="body mt-2"></div>
          <ul class="disc mt-2" v-if="tab.content.list">
            <li v-for="(item, index) in tab.content.list" :key="index">
              {{ item }}
            </li>
          </ul>
          <div class="svg-container pt-3 mt-auto">
            <img v-if="tab.content.img" :src="tab.content.img" />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { useResizeObserver } from "@vueuse/core";
import { ref, onMounted, watch, nextTick } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps({
  tabs: {
    type: [Array, Object],
    require: true,
    default: () => {
      return null;
    },
  },
});

const currentIndex = ref(0);
const swiper = ref();
const slide = ref();
const slider = ref();
/* const gradientLeft = ref();
const gradientRight = ref(); */
const { locale } = useI18n();

const toggleActive = function (index) {
  currentIndex.value = index;
  slider.value.style.cssText = `
    width: ${slide.value[index].offsetWidth}px;
    transform: translateX(${slide.value[index].offsetLeft}px)
  `;
};

watch(locale, () => {
  nextTick().then(() => {
    slider.value.style.cssText = `
      width: ${slide.value[currentIndex.value].offsetWidth}px;
      transform: translateX(${slide.value[currentIndex.value].offsetLeft}px)
    `;
  });
});

onMounted(() => {
  swiper.value.initialize();
  nextTick().then(() => {
    slide.value.forEach((el, index) => {
      useResizeObserver(el, (entries) => {
        const entry = entries[0];
        const { width, left } = entry.contentRect;
        if (entry.target.classList.contains("active")) {
          slider.value.style.cssText = `
            width: ${width}px;
            transform: translateX(${entry.target.offsetLeft}px)
          `;
        }
      });
    });
    /*  useIntersectionObserver(
      slide.value[0],
      ([entries], root) => {
        if (!entries.isIntersecting) {
          swiper.value.allowSlidePrev = true;
          gradientLeft.value.style.opacity = 1;
        } else {
          swiper.value.allowSlideprev = false;
          gradientLeft.value.style.opacity = 0;
        }
      },
      {
        root: swiper.value,
        threshold: 0.75,
      }
    );
    useIntersectionObserver(
      slide.value[slide.value.length - 1],
      ([entries], root) => {
        if (!entries.isIntersecting) {
          swiper.value.allowSlideNext = true;
          gradientRight.value.style.opacity = 1;
        } else {
          swiper.value.allowSlideNext = false;
          gradientRight.value.style.opacity = 0;
        }
      },
      {
        root: swiper.value,
        threshold: 0.95,
      }
    ); */
  });
});
</script>

<style lang="scss" scoped>
swiper-container {
  @include breakpoint-down(md) {
    overflow: visible;
  }
}

swiper-slide {
  width: auto;
  cursor: pointer;
  padding-top: 0.625rem;
  padding-bottom: 1.125rem;
  color: $blue;
  transition: color 0.2s ease-in-out;

  &:hover,
  &.active {
    color: $blue-dark;
  }
}
.slider {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50%;
  height: 6px;
  background-color: $blue;
  transition: width 0.3s ease-out, transform 0.4s ease-out;
}

.gradient {
  top: 0;
  width: 50px;
  height: 100%;
  opacity: 0;
  z-index: 10;
  pointer-events: none;
  transition: opacity 0.05s linear;
}
.gradient-left {
  left: calc(var(--padding-px) * -1);
  background: linear-gradient(90deg, $white, $transparent);
  @include breakpoint-up(md) {
    left: 0;
  }
}
.gradient-right {
  right: calc(var(--padding-px) * -1);
  background: linear-gradient(270deg, $white, $transparent);
  @include breakpoint-up(md) {
    right: 0;
  }
}
</style>
