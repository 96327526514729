<template>
  <FullpageSection
    class="references-slide bg-white pb-12"
    :title="$t('slide_references.title')"
  >
    <div
      class="references-prev swiper-button button-prev full row-4 display-md justify-self-start align-self-center z-mid"
    >
      <div class="flex justify-center items-center">
        <img src="@/assets/icons/arrow.svg" alt="" />
      </div>
    </div>
    <div class="swiper-container full row-md-4 w-full align-self-center">
      <swiper-container
        :navigation="{
          nextEl: '.references-next',
          prevEl: '.references-prev',
        }"
        :slides-per-view="'auto'"
        :loop="true"
        :watch-slides-progress="true"
        :update-on-window-resize="true"
        :autoplay="{
          delay: 3000,
          disableOnInteraction: false,
        }"
        :breakpoints="{
          768: {
            slidesPerView: 3,
          },
          900: {
            slidesPerView: 4,
          },
          1125: {
            slidesPerView: 5,
          },
          1320: {
            slidesPerView: 6,
          },
          1560: {
            slidesPerView: 7,
          },
          1800: {
            slidesPerView: 8,
          },
        }"
        :init="false"
        ref="swiper"
      >
        <swiper-slide v-for="(slide, index) in slides" :key="index">
          <div class="card flex flex-row-md w-full h-full px-6 py-1">
            <img
              :src="require(`@/assets/company-logos/${slide}.svg`)"
              :alt="`${slide} logo`"
              class="w-full h-full"
            />
          </div>
        </swiper-slide>
      </swiper-container>
    </div>
    <div
      class="references-next swiper-button button-next full row-4 display-md justify-self-end align-self-center z-mid"
    >
      <div class="flex justify-center items-center">
        <img src="@/assets/icons/arrow.svg" alt="" />
      </div>
    </div>
  </FullpageSection>
</template>

<script setup>
import { ref, onMounted } from "vue";

const swiper = ref();
const slides = ref([
  "abm",
  "delta-team",
  "generali",
  "ikona",
  "kad",
  "MJU",
  "MOL",
  "monetic",
  "nlb",
  "RMS",
  "SKB",
  "trigal",
]);

onMounted(() => {
  swiper.value.initialize();
});
</script>

<style lang="scss" scoped>
.references-slide {
  border-bottom: 1px solid $black !important;
}
.swiper-container {
  max-width: 1864px;
  @include breakpoint-up(md) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
swiper-slide {
  width: 13.375rem;
  height: 7.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid $gray-lines;

  &:not(.swiper-slide-visible) {
    + .swiper-slide-visible {
      border: none;

      &.swiper-slide-prev {
        + .swiper-slide-visible {
          border: none;
        }
      }
    }
  }
}
</style>
