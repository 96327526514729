<template>
  <header class="container absolute">
    <div ref="hidden" class="content content-span-1 absolute w-full"></div>
    <div :class="['logo content row-1 row-md-2 w-full']" @click="$emit('scrollToTop')">
      <img
        ref="logo"
        src="@/assets/logo_prop.svg"
        alt="BTC properties logo"
        class="w-full"
      />
    </div>
    <nav
      ref="nav"
      class="full row-1 flex justify-end items-end gap-5 text-tab w-full mx-auto"
    >
      <VueMenu />
      <div class="lang-switcher ml-1">
        <RouterLink
          :class="bgWhite ? 'blue' : 'blue-light'"
          :to="{ name: 'Landing', params: { lang: 'sl' } }"
          >SL</RouterLink
        >
        <span class="black px-1">·</span>
        <RouterLink
          :class="bgWhite ? 'blue' : 'blue-light'"
          :to="{ name: 'Landing', params: { lang: 'en' } }"
          >EN</RouterLink
        >
      </div>
    </nav>
  </header>
</template>

<script setup>
import VueMenu from "@/partials/layout/VueMenu.vue";
import { useI18n } from "vue-i18n";
import { useHead } from "@vueuse/head";
import { useEventListener, useElementSize } from "@vueuse/core";
import { ref, onMounted, watch } from "vue";

const props = defineProps({
  bgWhite: {
    typeof: Boolean,
    default: false,
  },
});
const hidden = ref();
const logo = ref();
const nav = ref();
const { t } = useI18n();
useHead({
  title: () => t("meta.title"),
  meta: [
    {
      name: "description",
      content: () => t("meta.description"),
    },
    {
      property: "og:description",
      content: () => t("meta.description"),
    },
    {
      property: "twitter:description",
      content: () => t("meta.description"),
    },
  ],
});

const root = document.querySelector(":root");
const { height: logoHeight } = useElementSize(logo);

watch(logoHeight, (newVal) => {
  root.style.setProperty("--logo-height", `${newVal * 0.845}px`);
  root.style.setProperty("--logo-height-ribbon", `${newVal * 0.156}px`);
});

onMounted(() => {
  setTimeout(() => {
    root.style.setProperty("--hidden-width", `${hidden.value.offsetWidth}px`);
    root.style.setProperty("--header-height", `${nav.value.offsetHeight}px`);
  }, 10);
});

useEventListener(window, "resize", () => {
  root.style.setProperty("--hidden-width", `${hidden.value.offsetWidth}px`);
  root.style.setProperty("--header-height", `${nav.value.offsetHeight}px`);
});
</script>

<style lang="scss" scoped>
nav {
  max-width: 1200px;
  padding-left: 1.75rem;
  padding-right: 1.75rem;
  @include breakpoint-down(md) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    flex-direction: column-reverse;
  }
  @include breakpoint-down(sm) {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  @include breakpoint-up(md) {
    align-items: center;
  }
}

header {
  z-index: $index-top;
  @include breakpoint-up(md) {
    position: fixed;
  }
}

.logo {
  transition: all 0.35s ease-in-out;
  @include breakpoint-up(md) {
    cursor: pointer;
    position: absolute;
    grid-column-end: span 2;
  }
  @include breakpoint-down(md) {
    max-width: 73.5px;
  }

  &.wider {
    width: calc(100% + var(--hidden-width) / 2);
  }
}

.lang-switcher {
  @include breakpoint-down(md) {
    margin-top: 1rem;
  }
}
</style>
