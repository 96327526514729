export default {
  title: 'STORITVE PRAV ZA VAS',
  flash: {
    title: 'Vaš objekt.<br>Naše znanje.',
    body: 'Vrhunske storitve, dolgoletne izkušnje in zadovoljni partnerji so tisto, kar je BTC PROP naredilo enega ključnih ponudnikov v segmentu upravljanja poslovnih nepremičnin.'
  },
  content: {
    title: 'Zakaj izbrati BTC PROP?',
    list: [
      'Smo specializirano podjetje za upravljanje poslovnih nepremičnin.',
      'Zaupajo nam največji nepremičninski skladi v Sloveniji.',
      'Zagotavljamo najvišjo raven storitve upravljanja.',
      'S partnerji poslujemo transparentno in gojimo ničelno toleranco do koruptivnih dejanj.',
      'Nudimo vam širok nabor visokostrokovnih izvajalcev.',
      'Imamo dolgoletne izkušnje s področja investicij (priprava projektov za energetske prenove, sodelovanje pri postavitvi sončnih elektrarn…).',
      'Ponašamo se z več kot 65-letno tradicijo upravljanja lastnih nepremičnin, storitev pa od leta 2013 nudimo tudi poslovnim partnerjem.'
    ]
  }
}