<template>
  <section class="fp-section" ref="slide">
    <div class="container overflow-hidden">
      <div
        class="content content-span-sm-8 content-start-md-2 content-span-md-5 row-md-3 align-self-end"
      >
        <h2 v-if="title" class="uppercase white var-pl pb-6">
          {{ title }}
        </h2>
      </div>
      <slot></slot>
    </div>
  </section>
</template>

<script setup>
import { ref } from "vue";
const props = defineProps({
  title: {
    typeof: String,
    default: "",
  },
});
const slide = ref();
defineExpose({
  slide,
});
</script>
