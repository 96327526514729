export default {
  body: `As the Business Unit of the BTC Company, <b>BTC PROP</b> provides business partners with top services of property portfolio management. As the leading provider of these services on the Slovenian market, BTC PROP manages over 600,000 m2 of surfaces, while also offering maintenance and consultancy services to our business partners. Attesting to the quality of our established property and asset management system for BTC PROP’s business partners is the ISO Asset Management Standard – ISO 55001. `,
  iso: require('@/assets/icons/ISO-white.svg'),
  stats: [
    {
      stat: 630000,
      name: 'm2 of business and commerce surfaces',
      onComplete: '630,000+',
      completed: false
    },
    {
      stat: 29,
      name: 'buildings',
      onComplete: '30+',
      completed: false
    },
    {
      stat: 14,
      name: 'partner companies',
      onComplete: '15+',
      completed: false
    },
  ]
}