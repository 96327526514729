export default {
  title: 'Users about us',
  slides: [
    {
      quote: 'We entrusted our property management to a group of experts who with their expertise, technical knowledge and transparency ensure the smooth operating of our buildings. They are a reliable partner, with a team which is available in every situation.',
      name: 'Generali Investments,',
      /* company_position: 'Vodja upravljanja nepremičnin', */
      company_name: 'družba za upravljanje, d. o. o.'
    },
    {
      quote: 'We’ve immediately noticed positive strides forward on our building after we entrusted BTC PROP with its management. They implemented positive changes with their expertise and reliability. Their proactiveness and the quality of service in every situation has strengthened our trust in them even more.',
      name: 'Kompas shop',
      /* company_position: 'Vodja upravljanja nepremičnin',
      company_name: 'Spar Slovenija d.o.o.' */
    },
    {
      quote: 'Integrated management with expert and committed staff who diligently carry out all the services for the owner as well as the lessees of our business buildings.',
      name: 'Trigal,',
      /* company_position: 'Vodja upravljanja nepremičnin', */
      company_name: 'upravljanje naložb in svetovalne storitve, d. o. o.'
    },
    {
      quote: 'With their expert services and consultancy, they help us preserve the value of our buildings and by implementing sustainable solutions they help us reduce negative impact on the environment. Their expert solutions ensure professional management and maintenance of properties.',
      name: 'Heliks Prop, d. o. o.',
      /* company_position: 'Vodja upravljanja nepremičnin',
      company_name: 'Spar Slovenija d.o.o.' */
    },
    {
      quote: "They take extra care in their management and maintenance of properties. With their reliable professional services, they ensure our uninterrupted business operations which reflects in our lessees' satisfaction.",
      name: 'Monetic',
      /* company_position: 'Vodja upravljanja nepremičnin',
      company_name: 'Spar Slovenija d.o.o.' */
    }
  ]
}