<template>
  <div :class="[{ error: errorMessage }, 'form-item']">
    <label :for="uuid" v-if="label">
      {{ label }}
    </label>
    <select
      v-bind="$attrs"
      @change="handler($event)"
      @blur="handleBlur"
      class="form-field border-bottom-error"
      :value="inputValue"
      :name="name"
      :id="uuid"
      :aria-describedby="errorMessage ? `${uuid}-error` : null"
      :aria-invalid="errorMessage ? true : null"
    >
      <option
        v-for="option in options"
        :value="option"
        :key="option"
        :selected="option === modelValue"
      >
        {{ option }}
      </option>
    </select>
    <BaseErrorMessage v-if="errorMessage" :id="`${uuid}-error`">
      {{ errorMessage }}
    </BaseErrorMessage>
  </div>
</template>

<script setup>
import UniqueID from "../helpers/UniqueID";
import { useField } from "vee-validate";
import { inject } from "vue";

const uuid = `options-${UniqueID().getID()}`;
const props = defineProps({
  label: {
    type: String,
    default: "",
  },
  modelValue: {
    type: [String, Number],
    default: "",
  },
  options: {
    type: Array,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
});

const formData = inject("FORM_DATA");

const {
  value: inputValue,
  errorMessage,
  handleChange,
  handleBlur,
} = useField(props.name, undefined);

const handler = function (event) {
  handleChange(event);
  formData.set(props.name, event.target.value);
};
</script>

<style></style>
