export default {
  title: 'Naročniki o nas',
  slides: [
    {
      quote: 'Upravljanje naših objektov smo zaupali ekipi strokovnjakov, ki s svojo strokovnostjo, tehničnim znanjem in transparentnostjo skrbi za njihovo nemoteno obratovanje. So zanesljiv partner, ki mu lahko zaupaš, s stalno ekipo in na katerega se lahko obrneš v vsaki situaciji.',
      name: 'Generali Investments,',
      /* company_position: 'Vodja upravljanja nepremičnin', */
      company_name: 'družba za upravljanje, d. o. o.'
    },
    {
      quote: 'Na objektu, ki ga je prevzel v upravljanje BTC PROP, smo takoj zaznali pozitivne premike naprej, ki so jih s svojo strokovnostjo in zanesljivostjo vnesli preko sistema upravljanja. Njihova proaktivnost in kakovost opravljenih storitev sta v vsaki situaciji še bolj okrepili naše zaupanje.',
      name: 'Kompas shop',
      /* company_position: 'Vodja upravljanja nepremičnin',
      company_name: 'Spar Slovenija d.o.o.' */
    },
    {
      quote: 'Celovito upravljanje, s strokovnim in predanim osebjem, ki skrbno opravlja vse storitve tako za lastnika kot najemnike naših poslovnih objektov.',
      name: 'Trigal,',
      /* company_position: 'Vodja upravljanja nepremičnin', */
      company_name: 'upravljanje naložb in svetovalne storitve, d. o. o.'
    },
    {
      quote: 'S svojim strokovnim delom in svetovanjem nam pomagajo ohranjati vrednost naših objektov ter s pomočjo trajnostnih rešitev zmanjševati negativen vpliv na okolje. S svojimi strokovnimi rešitvami nam zagotavljajo profesionalno upravljanje in vzdrževanje nepremičnin.',
      name: 'Heliks Prop, d. o. o.',
      /* company_position: 'Vodja upravljanja nepremičnin',
      company_name: 'Spar Slovenija d.o.o.' */
    },
    {
      quote: 'Objekte upravljajo in vzdržujejo izredno skrbno. S svojo zanesljivostjo storitev na strokovni ravni nam omogočajo nemoteno obratovanje in poslovanje, kar rezultira v zadovoljstvu naših najemnikov.',
      name: 'Monetic',
      /* company_position: 'Vodja upravljanja nepremičnin',
      company_name: 'Spar Slovenija d.o.o.' */
    }
  ]
}