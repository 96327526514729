export default {
  menu_title: 'BTC D.D. WEBSITES',
  sites: [
    {
      route: 'https://b2b.btc.si/',
      name: 'B2B portal'
    },
    {
      route: 'https://www.btc-city.com/en/business',
      name: 'BTC City Ljubljana'
    }
  ]
}
