<template>
  <div>
    <component :is="vertical ? 'p' : 'span'">
      File name: {{ file.name }}.
    </component>
    <component :is="vertical ? 'p' : 'span'">
      File size: {{ returnFileSize(file.size) }}.
    </component>
  </div>
  <div v-if="preview" class="img-preview">
    <img :src="filePreview" />
  </div>
</template>

<script setup>
import { inject, ref } from "vue";

const filePreview = ref();
const validFileType = inject("VALID");

const props = defineProps({
  file: {
    type: Object,
    require: true,
  },
  preview: {
    type: Boolean,
    require: true,
  },
  vertical: {
    type: Boolean,
    require: true,
  },
});

const returnFileSize = function (number) {
  if (number < 1024) return number + " bytes";
  else if (number >= 1024 && number < 1048576)
    return (number / 1024).toFixed(1) + "kB";
  else if (number >= 1048576) return (number / 1048576).toFixed(1) + "MB";
};

const fileImage = function (file) {
  return new Promise((res, rej) => {
    if (validFileType(file) == "heic") {
      heic2any({
        blob: file,
        toType: "image/jpeg",
        quality: 0.5,
      })
        .then((result) => {
          res(URL.createObjectURL(result));
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (validFileType(file)) res(URL.createObjectURL(file));
  });
};

const getFileImage = async function () {
  filePreview.value = await fileImage(props.file);
};

getFileImage();
</script>

<style lang="scss" scoped>
img {
  width: 100%;
  object-fit: contain;
}
</style>
