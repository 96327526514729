<template>
  <FullpageSection ref="slide" class="description-slide">
    <div
      class="content content-span-sm-8 content-start-md-2 content-span-md-5 row-md-4 var-pl"
    >
      <div class="separator bg-white"></div>
      <p
        class="white var-padding pb-5 text-flash"
        v-html="$t('slide_description.body')"
      ></p>
      <img :src="$t('slide_description.iso')" alt="" class="mb-8" />
    </div>
    <div
      class="stats content content-span-5 content-span-md-3 row-md-4 row-span-md-3 w-full h-full relative"
    >
      <img ref="img" src="@/assets/building.svg" alt="" class="w-full absolute" />
    </div>
    <ul
      class="content content-start-6 content-start-md-10 content-span-md-3 row-md-4 pb-20"
      ref="statsParent"
    >
      <li
        class="flex flex-column white"
        v-for="(stat, index) in messages[locale].slide_description.stats"
      >
        <span ref="statsRef" class="h1 mb-1">{{
          counter
            ? messages[locale].slide_description.stats[index].completed
              ? messages[locale].slide_description.stats[index].onComplete
              : counter[index]
            : stat.stat
        }}</span>
        <span>{{ stat.name }}</span>
      </li>
    </ul>
  </FullpageSection>
</template>

<script setup>
import { useI18n } from "vue-i18n";
import { ref, onMounted, watch } from "vue";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useParallax } from "@/composables/ScrollTriggers";

gsap.registerPlugin(ScrollTrigger);

const { messages, locale, availableLocales } = useI18n();
const props = defineProps({
  visited: {
    typeof: Boolean,
    default: false,
  },
});

const statsRef = ref();
const statsParent = ref();
const slide = ref();
const img = ref();
const stats = messages.value[locale.value].slide_description.stats.map((el) => el.stat);
const counter = ref(false);
let timeline;

onMounted(() => {
  /* useParallax(img.value, slide.value.slide, {
    endTop: 0,
    start: "top bottom",
    end: "bottom bottom",
  }); */
  const numFormatSL = new Intl.NumberFormat("de-DE");
  const numFormatEN = new Intl.NumberFormat("en-US");
  counter.value = new Array(statsRef.value.length);
  counter.value.fill(0);

  timeline = gsap.timeline({
    scrollTrigger: {
      trigger: statsParent.value,
      start: "top center",
      once: true,
    },
  });

  statsRef.value.forEach((_, index) => {
    const counting = { val: 0 };
    const delay = index === 0 ? "" : "<+=1";
    timeline.to(
      counting,
      {
        val: stats[index],
        duration: 3,
        ease: "ease-in",
        onUpdate: () => {
          counter.value[index] =
            locale.value === "sl"
              ? numFormatSL.format(gsap.utils.snap(1, counting.val))
              : numFormatEN.format(gsap.utils.snap(1, counting.val));
        },
        onComplete: () => {
          if (messages.value[locale.value].slide_description.stats[index].onComplete) {
            availableLocales.forEach((availableLocale) => {
              messages.value[availableLocale].slide_description.stats[
                index
              ].completed = true;
            });
          }
        },
      },
      delay
    );
  });
});

watch(props, (newVal) => {
  if (newVal.visited)
    setTimeout(() => {
      timeline.play();
    }, 750);
});
</script>

<style lang="scss" scoped>
.stats {
  @include breakpoint-up(md) {
    grid-column-start: half;
  }
}
.description-slide {
  @include breakpoint-down(md) {
    padding-top: 1.75rem;
    padding-bottom: 1rem;
  }
}
.h1 {
  white-space: nowrap;
}
li {
  padding-top: var(--logo-height-ribbon);
  padding-bottom: var(--logo-height-ribbon);
  border-top: 1px dashed $white;
  &:last-child {
    border-bottom: 1px dashed $white;
  }
}
</style>
