import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { onMounted, ref } from 'vue';

gsap.registerPlugin(ScrollTrigger);

export function useParallax(element, target, { endX = 0, endY = 0, endTop = 0, start = 'top top', end = 'bottom bottom', ease = 'linear', scrub = true } = {}) {
  gsap.to(element, {
    scrollTrigger: {
      trigger: target,
      start: start,
      end: end,
      scrub: scrub,
      markers: true
    },
    x: endX,
    y: endY,
    top: endTop,
    ease: ease
  })
}

/* export function useCountUp( counters, parent, endVals, { once = true, start = 'top bottom', end = 'bottom bottom', duration = 1.5, ease = 'ease-in', stagger = 0.5 } = {}) {
  const counter = ref(false)
  onMounted(() => {
    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: parent.value,
        start: start,
        end: end,
        once: once,
        stagger: stagger,
        markers: true
      }
    })
    counter.value = new Array(counters.value.length)
    counter.value.fill(0)
    const numFormat = new Intl.NumberFormat('de-DE')
    counters.value.forEach((el, index) => {
      const counting = { val: 0 }
      timeline.to(
        counting,
        {
          val: endVals[counters.value[index]],
          duration: 1.5,
          ease: 'ease-in',
          onUpdate: () => {
            counter.value[index] = numFormat.format(gsap.utils.snap( 1, counting.val ));
          }
        }
      )
    })
  })

  return {
    counter
  }
} */

/* if( this.counter ) {
  this.parent = this.counter.closest( parent )
  gsapTimeline.value = gsap.timeline({
    scrollTrigger: {
      trigger: this.parent,
      start: "top center",
      once: true
    }
  })
  
  gsap.utils.toArray( counter ).forEach( el => {
    const startValue = el.dataset.startVal
    const endValue = el.dataset.endVal
    const counting = { val: startValue }
    el.innerHTML = startValue

    gsapTimeline.value.to( 
      counting, 
      {
        val: endValue,
        duration: 1.5,
        ease: 'ease-in',
        onUpdate: () => {
          const number = gsap.utils.snap( 1, counting.val )
          el.innerHTML = number
        }
      },
      '>-1'
    )
  })
} */

/* ScrollTrigger.batch(wrapper.value.children, {
  once: props.once == 'true' ? true : false,
  start: props.start,
  onEnter: batch => {
    switch(props.direction) {
      case ('from'):
        gsap.from(batch, JSON.parse(props.options))
        break;
      case ('timeline'):
        const mainTimeline = gsap.timeline()
        const options = [ ...JSON.parse(props.options) ]
        batch.forEach((el, i) => {
          const target = props.selector ? el.querySelector(props.selector) : el
          const singleTimeline = gsap.timeline()
          options.forEach(option => {
            singleTimeline.to(target, {...option})
          })
          mainTimeline.add(singleTimeline, i * props.stagger)
        })
        break;
      case ('to'):
        gsap.to(batch, JSON.parse(props.options))
        break;
    }
  }
}) */