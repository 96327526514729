<template>
  <div ref="menu" class="menu relative blue-light bg-blue-dark">
    <button
      class="flex items-center uppercase gap-5 text-tab p-4"
      @click="menuOpen = !menuOpen"
    >
      {{ $t("header.menu_title") }}
      <svg
        width="15"
        height="9"
        viewBox="0 0 15 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.7375 0.468842C14.6464 0.395925 14.5461 0.341237 14.4368 0.304779C14.3456 0.250092 14.2362 0.222748 14.1086 0.222748C13.9993 0.222748 13.8899 0.250092 13.7805 0.304779C13.6711 0.341237 13.58 0.395925 13.5071 0.468842L7.98364 5.99228L2.48755 0.468842C2.3964 0.395925 2.29614 0.341237 2.18677 0.304779C2.09562 0.250092 1.98625 0.222748 1.85864 0.222748C1.62166 0.222748 1.41203 0.313894 1.22974 0.496185C1.06567 0.660248 0.983643 0.860769 0.983643 1.09775C0.983643 1.22535 1.00187 1.34384 1.03833 1.45322C1.09302 1.54436 1.15682 1.63551 1.22974 1.72665L7.35474 7.85165C7.44588 7.92457 7.53703 7.98837 7.62817 8.04306C7.73755 8.07952 7.85604 8.09775 7.98364 8.09775C8.09302 8.09775 8.20239 8.07952 8.31177 8.04306C8.42114 7.98837 8.51229 7.92457 8.58521 7.85165L14.7102 1.72665C14.8014 1.63551 14.8652 1.54436 14.9016 1.45322C14.9563 1.34384 14.9836 1.22535 14.9836 1.09775C14.9836 0.970144 14.9563 0.860769 14.9016 0.769623C14.8652 0.660248 14.8105 0.559987 14.7375 0.468842Z"
          fill="#C6E4F9"
        />
      </svg>
    </button>
    <Transition name="fade">
      <ul
        v-show="menuOpen"
        class="absolute overflow-hidden bg-blue-dark w-full uppercase py-2"
      >
        <li v-for="site in messages[locale].header.sites" class="px-4 py-2">
          <a :href="site.route" class="w-full">{{ site.name }}</a>
        </li>
      </ul>
    </Transition>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { onClickOutside } from "@vueuse/core";

const { messages, locale } = useI18n();

const menuOpen = ref(false);
const menu = ref();

onClickOutside(menu, () => {
  menuOpen.value = false;
});
</script>

<style lang="scss" scoped>
ul {
  max-height: 500px;
}
</style>

<style lang="scss">
nav {
  .router-link-active {
    color: $white;
    &.blue {
      color: $gray-text;
    }
  }
}
li a {
  display: block;
}
</style>
