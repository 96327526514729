<template>
  <input
    type="radio"
    :name="name"
    :checked="value"
    :value="value"
    @change="handler($event)"
    :id="uuid"
  />
  <label :for="uuid" v-if="label">
    {{ label }}
  </label>
  <p v-if="description">
    {{ description }}
  </p>
</template>

<script setup>
import UniqueID from "../helpers/UniqueID";
import { useField } from "vee-validate";
import { inject } from "vue";

const uuid = `radio-${UniqueID().getID()}`;
const props = defineProps({
  label: {
    type: String,
    default: "",
  },
  description: {
    type: String,
    default: "",
  },
  value: {
    type: [String, Number],
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
});

const formData = inject("FORM_DATA");

const { handleChange } = useField(props.name, undefined, {
  type: "radio",
});

const handler = function (event) {
  handleChange(event.target.value);
  formData.set(props.name, event.target.value);
};
</script>

<style></style>
