export default {
  title: 'GLAVNE PREDNOSTI',
  flash: {
    title: 'Poslujmo učinkoviteje.',
    body: 'BTC PROP je odgovor na potrebe po racionalizaciji poslovanja, učinkovitem upravljanju virov, trajnostnem razvoju, uporabi informacijsko-komunikacijskih tehnologij in drugih razvojnih trendih.'
  },
  tabs: [
    {
      title: 'IZKUŠNJE',
      content: {
        intro: 'Raznoliko znanje in izkušnje',
        body: 'Našo ekipo sestavljajo uveljavljeni strokovnjaki z znanji z različnih strokovnih področij, zato svojim poslovnim partnerjem nudimo zelo širok nabor specializiranih storitev.',
        /* list: [
          'skrb za redno vzdrževanje in obratovanje skupnih delov,',
          'izvajanje sklepov, ki jih sprejmejo lastniki,',
          'sestava mesečnega obračuna stroškov, razdeljevanje in izterjava le-teh,',
          'priprava načrta vzdrževanja in upravljanje z rezervnim skladom na podlagi sprejetega načrta,',
          'zastopanje lastnikov v pravnem prometu,',
          'vložitev izključitvene tožbe ter tožbe za plačilo stroškov, ki bremenijo lastnika,',
          'priprava poročil'
          img: require('@/assets/icons/ISO-blue.svg')
        ] */
      }
    },
    {
      title: 'ENA VSTOPNA TOČKA',
      content: {
        intro: 'Ena vstopna točka za naročnika',
        body: 'Za operativno in vsakodnevno sodelovanje vam je na voljo ena oseba, ki bo v vsakem trenutku vedela, kaj storiti, in hitro in učinkovito poskrbela za rešitev.',
        /* list: [
          'skrb za redno vzdrževanje in obratovanje skupnih delov,',
          'izvajanje sklepov, ki jih sprejmejo lastniki,',
          'sestava mesečnega obračuna stroškov, razdeljevanje in izterjava le-teh,',
          'priprava načrta vzdrževanja in upravljanje z rezervnim skladom na podlagi sprejetega načrta,',
          'zastopanje lastnikov v pravnem prometu,',
          'vložitev izključitvene tožbe ter tožbe za plačilo stroškov, ki bremenijo lastnika,',
          'priprava poročil'
          img: require('@/assets/icons/ISO-blue.svg')
        ] */
      }
    },
    {
      title: 'TRANSPARENTNOST',
      content: {
        intro: 'Transparentnost poslovanja',
        body: 'Z različnimi IT orodji omogočamo redne in transparentne procese. ',
        /* list: [
          'skrb za redno vzdrževanje in obratovanje skupnih delov,',
          'izvajanje sklepov, ki jih sprejmejo lastniki,',
          'sestava mesečnega obračuna stroškov, razdeljevanje in izterjava le-teh,',
          'priprava načrta vzdrževanja in upravljanje z rezervnim skladom na podlagi sprejetega načrta,',
          'zastopanje lastnikov v pravnem prometu,',
          'vložitev izključitvene tožbe ter tožbe za plačilo stroškov, ki bremenijo lastnika,',
          'priprava poročil'
          img: require('@/assets/icons/ISO-blue.svg')
        ] */
      }
    },
    {
      title: 'PODPORA',
      content: {
        intro: 'Podpora v upravljanju',
        body: 'Z izkušnjami optimiziramo in urejamo procese pogodbenih odnosov v imenu etažnih lastnikov.',
        /* list: [
          'skrb za redno vzdrževanje in obratovanje skupnih delov,',
          'izvajanje sklepov, ki jih sprejmejo lastniki,',
          'sestava mesečnega obračuna stroškov, razdeljevanje in izterjava le-teh,',
          'priprava načrta vzdrževanja in upravljanje z rezervnim skladom na podlagi sprejetega načrta,',
          'zastopanje lastnikov v pravnem prometu,',
          'vložitev izključitvene tožbe ter tožbe za plačilo stroškov, ki bremenijo lastnika,',
          'priprava poročil'
          img: require('@/assets/icons/ISO-blue.svg')
        ] */
      }
    },
    {
      title: 'ZANESLJIVOST',
      content: {
        intro: 'Zanesljivost storitve',
        body: 'Učinkovito izvajamo dogovorjeni obseg storitev.',
        /* list: [
          'skrb za redno vzdrževanje in obratovanje skupnih delov,',
          'izvajanje sklepov, ki jih sprejmejo lastniki,',
          'sestava mesečnega obračuna stroškov, razdeljevanje in izterjava le-teh,',
          'priprava načrta vzdrževanja in upravljanje z rezervnim skladom na podlagi sprejetega načrta,',
          'zastopanje lastnikov v pravnem prometu,',
          'vložitev izključitvene tožbe ter tožbe za plačilo stroškov, ki bremenijo lastnika,',
          'priprava poročil'
          img: require('@/assets/icons/ISO-blue.svg')
        ] */
      }
    },
  ]
}