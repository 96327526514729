<template>
  <FullpageSection class="services-slide" :title="$t('slide_services.title')">
    <div class="content content-span-sm-8 content-start-md-2 content-span-md-5 row-md-4 var-pl">
      <div class="separator bg-yellow"></div>
      <p class="white var-padding pb-8 text-flash">
        <span class="h1 block pb-4">{{ $t('slide_services.flash.title') }}</span>
        {{ $t('slide_services.flash.body') }}
      </p>
    </div>
    <div class="content row-md-4 card card-1">
      <div class="flex flex-column-md gap-4">
        <img :src="$t('slide_services.card_1.img')" alt="">
        <div class="white">
          <b class="text-flash">{{ $t('slide_services.card_1.title') }}</b>
          <p v-if="messages[locale].slide_services.card_1.body" class="mt-3">{{ $t('slide_services.card_1.body') }}</p>
          <ul v-if="messages[locale].slide_services.card_1.list" class="disc mt-4">
            <li v-for="item, index in messages[locale].slide_services.card_1.list" :key="index">{{ item }}</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="content row-md-4 card card-2">
      <div class="flex flex-column-md gap-4">
        <img :src="$t('slide_services.card_2.img')" alt="">
        <div class="white">
          <b class="text-flash">{{ $t('slide_services.card_2.title') }}</b>
          <p v-if="messages[locale].slide_services.card_2.body" class="mt-3">{{ $t('slide_services.card_2.body') }}</p>
          <ul v-if="messages[locale].slide_services.card_2.list" class="disc mt-4">
            <li v-for="item, index in messages[locale].slide_services.card_2.list" :key="index">{{ item }}</li>
          </ul>
        </div>
      </div>
    </div>
  </FullpageSection>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
const { messages, locale} = useI18n()
</script>

<style lang="scss" scoped>
.card {
  @include breakpoint-down (md) {
    padding-top: 1.25rem;
    border-top: 1px dashed $black;
  }
  @include breakpoint-up (md) {
    border-left: 1px dashed $black;
    padding-left: calc(var(--gap) / 2);
  }
  img {
    width: fit-content;
    height: auto;
  }
}
.card-1 {
  margin-bottom: 1.25rem;
  @include breakpoint-up (md) {
    grid-column: half / span 6;
  }
}

.card-2 {
  margin-bottom: 1.25rem;
  @include breakpoint-up (md) {
    grid-column: 21 / content-end;
    margin-left: calc(var(--gap) / 2);
  }
}
</style>