<template>
  <FullpageSection class="for-you-slide" :title="$t('slide_for_you.title')">
    <div class="content content-span-sm-8 content-start-md-2 content-span-md-5 row-md-4 var-pl">
      <div class="separator bg-yellow"></div>
      <p class="white var-padding pb-8 text-flash">
        <span class="h1 block pb-4" v-html="$t('slide_for_you.flash.title')"></span>
        {{ $t('slide_for_you.flash.body') }}
      </p>
    </div>
    <div class="content content-start-md-7 content-span-md-6 row-md-4 mb-5">
      <b class="text-flash white">{{ $t('slide_for_you.content.title') }}</b>
      <ul class="disc white mt-2">
        <li v-for="item, index in messages[locale].slide_for_you.content.list" :key="index">
          {{ item }}
        </li>
      </ul>
    </div>
  </FullpageSection>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
const { messages, locale} = useI18n()
</script>

<style lang="scss" scoped>

</style>