export default {
  body: `<b>BTC PROP</b>, poslovna enota družbe BTC, svojim poslovnim partnerjem zagotavlja prvovrstne storitve upravljanja portfelja nepremičnin. BTC PROP je vodilni ponudnik tovrstnih storitev na slovenskem trgu, ki upravlja skupaj več kot 600.000 m2 površin, ter izvaja tudi storitve vzdrževanja in svetovanja. Kakovost vzpostavljenega sistema upravljanja z nepremičninami in premoženjem poslovnih partnerjev potrjuje ISO standard za upravljanje premoženja – ISO 55001.`,
  iso: require('@/assets/icons/ISO-white.svg'),
  stats: [
    {
      stat: 630000,
      name: 'm2 poslovnih in prodajnih površin',
      onComplete: '630.000+',
      completed: false
    },
    {
      stat: 29,
      name: 'objektov',
      onComplete: '30+',
      completed: false
    },
    {
      stat: 14,
      name: 'partnerskih podjetij',
      onComplete: '15+',
      completed: false
    },
  ]
}