<template>
  <div :class="[{ error: errorMessage }, 'form-item']">
    <label :for="uuid" v-if="props.label">
      {{ props.label }}
    </label>
    <component
      :is="props.rows ? 'textarea' : 'input'"
      v-bind="$attrs"
      :type="$attrs.type ? $attrs.type : 'text'"
      class="form-field border-bottom-error"
      :name="props.name"
      :placeholder="props.placeholder ? props.placeholder : props.label"
      :rows="props.rows ? props.rows : null"
      :value="inputValue"
      :id="uuid"
      :aria-describedby="errorMessage ? `${uuid}-error` : null"
      :aria-invalid="errorMessage ? true : null"
      @input="handler($event)"
      @blur="handleBlur"
    >
    </component>
    <div class="error-message-wrap" v-if="props.showError">
      <BaseErrorMessage
        v-if="props.lazy ? errorMessage && meta.touched : errorMessage"
        :id="`${uuid}-error`"
      >
        {{ errorMessage }}
      </BaseErrorMessage>
    </div>
  </div>
</template>

<script setup>
import UniqueID from "../helpers/UniqueID";
import { useField } from "vee-validate";
import { inject } from "vue";
const uuid = `input-${UniqueID().getID()}`;

const props = defineProps({
  label: {
    type: String,
    default: "Vnesite text",
  },
  placeholder: {
    type: [String, Number],
    default: "",
  },
  rows: {
    type: Number,
    default: 0,
  },
  name: {
    type: String,
    default: "input-text",
  },
  lazy: {
    type: Boolean,
    default: false,
  },
  showError: {
    type: Boolean,
    default: true,
  },
});

const formData = inject("FORM_DATA");

const {
  value: inputValue,
  errorMessage,
  handleBlur,
  handleChange,
  meta,
} = useField(props.name, undefined, {
  initialValue: props.value,
});

const handler = function (event) {
  handleChange(event);
  formData.set(props.name, event.target.value);
};
</script>

<style></style>
