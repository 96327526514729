import { createI18n } from 'vue-i18n'
import sl from "./locales/sl"
import en from "./locales/en"

export default createI18n({
  legacy: false,
  locale: "sl",
  fallbackLocale: "sl",
  messages: {
    sl: sl,
    en: en,
  },
});