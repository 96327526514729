<template>
  <VueFullpage> </VueFullpage>
  <CookieConsent />
</template>

<script setup>
import { defineAsyncComponent } from "vue";
import VueFullpage from "@/components/VueFullpage.vue";
const CookieConsent = defineAsyncComponent({
  loader: () => import("@/partials/layout/CookieConsent.vue"),
});
</script>

<style lang="scss" scoped></style>
