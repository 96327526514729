import { createApp } from 'vue';
import { createHead } from "@vueuse/head";

import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";

import i18n from '@/lang/i18n';
import router from "@/router";
import App from '@/App.vue';
import FullpageSection from './components/FullpageSection.vue';
import { register } from 'swiper/element/bundle';

/* register Swiper custom elements */
register()

/* declare createHead */
const head = createHead()
const app = createApp(App)

const requireInput = require.context(
  "./components/form/inputs",
  false,
  /Base[A-Z]\w+\.(vue|js)$/
);

const requireElement = require.context(
  "./components/form/elements",
  false,
  /Base[A-Z]\w+\.(vue|js)$/
);

const requireWizard = require.context(
  "./components/form/wizard",
  false,
  /Base[A-Z]\w+\.(vue|js)$/
);

requireInput.keys().forEach((fileName) => {
  const componentConfig = requireInput(fileName);

  const componentName = upperFirst(
    camelCase(fileName.replace(/^\.\/(.*)\.\w+$/, "$1"))
  );

  app.component(componentName, componentConfig.default || componentConfig);
});

requireElement.keys().forEach((fileName) => {
  const componentConfig = requireElement(fileName);

  const componentName = upperFirst(
    camelCase(fileName.replace(/^\.\/(.*)\.\w+$/, "$1"))
  );

  app.component(componentName, componentConfig.default || componentConfig);
});

requireWizard.keys().forEach((fileName) => {
  const componentConfig = requireWizard(fileName);

  const componentName = upperFirst(
    camelCase(fileName.replace(/^\.\/(.*)\.\w+$/, "$1"))
  );

  app.component(componentName, componentConfig.default || componentConfig);
});

app
  .component('FullpageSection', FullpageSection)
  .use(head)
  .use(router)
  .use(i18n)
  .mount('#app')
