export default {
  menu_title: 'Spletne strani BTC d.d.',
  sites: [
    {
      route: 'https://b2b.btc.si/',
      name: 'B2B portal'
    },
    {
      route: 'https://www.btc-city.com/sl/poslovno',
      name: 'BTC City Ljubljana'
    }
  ]
}