<template>
  <div class="dialog-box-holder">
    <!-- Success -->
    <div class="dialog-box">
      <div class="text-title">
        <div class="icon-holder">
          <svg
            v-if="success"
            class="icon-success"
            id="Layer_1"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xml:space="preserve"
            enable-background="new 0 0 48 48"
            height="100%"
            width="100%"
            viewBox="0 0 48 48"
          >
            <g id="Layer_3">
              <polygon
                points="34.202,14.978 28.426,20.755 28.436,20.764 20.559,28.641 13.791,21.871 11.596,24.066    19.354,31.825 19.347,31.833 20.537,33.021 20.544,33.016 20.551,33.021 21.082,32.492 21.087,32.497 30.627,22.957 30.627,22.957    36.404,17.18  "
              />
              <path
                d="M24,0C10.745,0,0,10.745,0,24s10.745,24,24,24s24-10.745,24-24S37.255,0,24,0z M24,44   C12.954,44,4,35.046,4,24S12.954,4,24,4s20,8.954,20,20S35.046,44,24,44z"
              />
            </g>
          </svg>

          <svg
            v-if="error && !success"
            class="icon-error"
            id="Layer_1"
            version="1.1"
            xml:space="preserve"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            enable-background="new 0 0 48 48"
            height="100%"
            width="100%"
            viewBox="0 0 48 48"
          >
            <g id="Layer_3">
              <g>
                <path
                  d="M24,0C10.745,0,0,10.745,0,24s10.745,24,24,24s24-10.745,24-24S37.255,0,24,0z M24,44    C12.954,44,4,35.046,4,24S12.954,4,24,4s20,8.954,20,20S35.046,44,24,44z"
                />
                <polygon
                  points="33.878,12.003 24,21.88 14.123,12.003 12.003,14.125 21.879,24.001 12.003,33.877 14.126,35.997     24,26.123 33.875,35.997 35.997,33.877 26.122,24.001 35.997,14.125   "
                />
              </g>
            </g>
          </svg>
        </div>
        <span
          ><b>{{ title }}</b></span
        >
      </div>
      <span class="text-message text-center"
        ><b>{{ message }}</b></span
      >
    </div>
  </div>
</template>

<script setup>
/* eslint-disable */
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const emits = defineEmits(["close-dialog"]);
const props = defineProps({
  success: {
    type: Boolean,
    required: true,
  },
  error: {
    type: Boolean,
    required: true,
  },
});

const message = computed(() => {
  if (props.success) return t("contact_form.successMessage.message");
  if (props.error && !props.success) return t("contact_form.errorMessage.message");
});

const title = computed(() => {
  if (props.success) return t("contact_form.successMessage.title");
  if (props.error && !props.success) return t("contact_form.errorMessage.title");
});

setTimeout(() => {
  emits("close-dialog");
}, 3000);
</script>

<style lang="scss" scoped>
//// Dialog Box ////

.dialog-box-holder {
  z-index: 9999;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.dialog-box {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffffc3;
  box-shadow: 0 0 20px -8px #00000040;
  padding: 1.5rem 1.25rem 1.75rem;
  @include breakpoint-up(md) {
    padding: 1.5rem 2rem 2rem;
  }
}

.icon-holder {
  flex: 0 0 45px;
  min-width: 45px;
  padding-top: 4px;
  padding-right: 15px;
  margin-left: -30px;
}
.icon-success {
  path,
  polygon {
    fill: var(--color-primary, #19a974);
  }
}
.icon-error {
  path,
  polygon {
    fill: var(--color-error, #ff4136);
  }
}

.text-holder {
  display: flex;
  flex-direction: column;
  flex: 0 1 100%;
}

.text-title {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  b {
    text-transform: uppercase;
    font-size: 1.5rem;
    line-height: 100%;
    letter-spacing: 0.05em;
    font-family: "futura-bold";
    color: $blue-dark;
  }
}

.button-holder {
  margin-top: 40px;
  button {
    display: block;
    color: var(--color-base, #e4e4e4);
    background-color: #0000;
    padding: 0;
    outline: none;
    border: none;
    font-size: 0.8rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    user-select: none;
    cursor: pointer;
  }
}

//// Transition ////
</style>
