import meta from './sl/meta';
import header from './sl/header';
import slide_home from './sl/slide_home';
import slide_description from './sl/slide_description';
import slide_real_estate from './sl/slide_real_estate';
import slide_services from './sl/slide_services';
import slide_pillars from './sl/slide_pillars';
import slide_advantages from './sl/slide_advantages';
import slide_for_you from './sl/slide_for_you';
import slide_trusted_by from './sl/slide_trusted_by';
import slide_contact_us from './sl/slide_contact_us';
import contact_form from './sl/contact_form';
import errorMessages from './sl/errorMessages';
import footer from './sl/footer';
import cookies from './sl/cookies';

export default {
  meta: meta,
  header: header,
  slide_home: slide_home,
  slide_description: slide_description,
  slide_real_estate: slide_real_estate,
  slide_services: slide_services,
  slide_pillars: slide_pillars,
  slide_advantages: slide_advantages,
  slide_for_you: slide_for_you,
  slide_trusted_by: slide_trusted_by,
  slide_references: {
    title: 'Reference'
  },
  slide_contact_us: slide_contact_us,
  contact_form: contact_form,
  errorMessages: errorMessages,
  footer: footer,
  cookies: cookies
};