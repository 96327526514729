import meta from './en/meta';
import header from './en/header';
import slide_home from './en/slide_home';
import slide_description from './en/slide_description';
import slide_real_estate from './en/slide_real_estate';
import slide_services from './en/slide_services';
import slide_pillars from './en/slide_pillars';
import slide_advantages from './en/slide_advantages';
import slide_for_you from './en/slide_for_you';
import slide_trusted_by from './en/slide_trusted_by';
import slide_contact_us from './en/slide_contact_us';
import contact_form from './en/contact_form';
import errorMessages from './en/errorMessages';
import footer from './en/footer';
import cookies from './en/cookies';

export default {
  meta: meta,
  header: header,
  slide_home: slide_home,
  slide_description: slide_description,
  slide_real_estate: slide_real_estate,
  slide_services: slide_services,
  slide_pillars: slide_pillars,
  slide_advantages: slide_advantages,
  slide_for_you: slide_for_you,
  slide_trusted_by: slide_trusted_by,
  slide_references: {
    title: 'References'
  },
  slide_contact_us: slide_contact_us,
  contact_form: contact_form,
  errorMessages: errorMessages,
  footer: footer,
  cookies: cookies
};