export default {
  title: 'The right service for you',
  flash: {
    title: 'Your property. <br> Our knowledge.',
    body: 'Superior services, many years of experience and satisfied partners are what made BTC PROP one of the key providers in the commercial real estate management segment.'
  },
  content: {
    title: 'Why choose BTC PROP?',
    list: [
      'We are a company specialising in business property management.',
      'The biggest property funds in Slovenia have entrusted us with their properties.',
      'We provide top management services.',
      'We conduct business with our partners transparently and have zero tolerance for corruptive practices.',
      'We offer a wide selection of highly professional service providers.',
      'We have long-standing investment experience (project preparation for energy renovation, cooperation in setting up solar plants, etc.).',
      'Our own-property management tradition spans over 65 years, while this service has been offered to our business partners since 2013.'
    ]
  }
}