export default {
  title: 'Main Advantages',
  flash: {
    title: 'Optimising business operations.',
    body: 'BTC PROP is a response to the need for business rationalization, efficient management of resources, sustainable development, use of information and communication technologies and other development trends.'
  },
  tabs: [
    {
      title: 'EXPERIENCE',
      content: {
        intro: 'Diverse knowledge and experience',
        body: 'Our team includes established experts with knowledge of different expert fields, which allows us to provide our clients with a wide array of specialised services.',
        /* list: [
          'skrb za redno vzdrževanje in obratovanje skupnih delov,',
          'izvajanje sklepov, ki jih sprejmejo lastniki,',
          'sestava mesečnega obračuna stroškov, razdeljevanje in izterjava le-teh,',
          'priprava načrta vzdrževanja in upravljanje z rezervnim skladom na podlagi sprejetega načrta,',
          'zastopanje lastnikov v pravnem prometu,',
          'vložitev izključitvene tožbe ter tožbe za plačilo stroškov, ki bremenijo lastnika,',
          'priprava poročil'
          img: require('@/assets/icons/ISO-blue.svg')
        ] */
      }
    },
    {
      title: 'SINGLE ENTRY POINT',
      content: {
        intro: 'Single entry point for the client',
        body: 'We select one single competent person who is in regular contact with you to ensure operational daily cooperation with the client.',
        /* list: [
          'skrb za redno vzdrževanje in obratovanje skupnih delov,',
          'izvajanje sklepov, ki jih sprejmejo lastniki,',
          'sestava mesečnega obračuna stroškov, razdeljevanje in izterjava le-teh,',
          'priprava načrta vzdrževanja in upravljanje z rezervnim skladom na podlagi sprejetega načrta,',
          'zastopanje lastnikov v pravnem prometu,',
          'vložitev izključitvene tožbe ter tožbe za plačilo stroškov, ki bremenijo lastnika,',
          'priprava poročil'
          img: require('@/assets/icons/ISO-blue.svg')
        ] */
      }
    },
    {
      title: 'TRANSPARENCY',
      content: {
        intro: 'Transparency of business operations',
        body: 'We provide for regular and transparent processes by using different IT tools.',
        /* list: [
          'skrb za redno vzdrževanje in obratovanje skupnih delov,',
          'izvajanje sklepov, ki jih sprejmejo lastniki,',
          'sestava mesečnega obračuna stroškov, razdeljevanje in izterjava le-teh,',
          'priprava načrta vzdrževanja in upravljanje z rezervnim skladom na podlagi sprejetega načrta,',
          'zastopanje lastnikov v pravnem prometu,',
          'vložitev izključitvene tožbe ter tožbe za plačilo stroškov, ki bremenijo lastnika,',
          'priprava poročil'
          img: require('@/assets/icons/ISO-blue.svg')
        ] */
      }
    },
    {
      title: 'SUPPORT',
      content: {
        intro: 'Management support',
        body: 'We ensure optimisation and management of your contractual relationships and processes on behalf of property owners.',
        /* list: [
          'skrb za redno vzdrževanje in obratovanje skupnih delov,',
          'izvajanje sklepov, ki jih sprejmejo lastniki,',
          'sestava mesečnega obračuna stroškov, razdeljevanje in izterjava le-teh,',
          'priprava načrta vzdrževanja in upravljanje z rezervnim skladom na podlagi sprejetega načrta,',
          'zastopanje lastnikov v pravnem prometu,',
          'vložitev izključitvene tožbe ter tožbe za plačilo stroškov, ki bremenijo lastnika,',
          'priprava poročil'
          img: require('@/assets/icons/ISO-blue.svg')
        ] */
      }
    },
    {
      title: 'RELIABILITY',
      content: {
        intro: 'Service reliability',
        body: 'Regular and efficient implementation of the agreed extent of services.',
        /* list: [
          'skrb za redno vzdrževanje in obratovanje skupnih delov,',
          'izvajanje sklepov, ki jih sprejmejo lastniki,',
          'sestava mesečnega obračuna stroškov, razdeljevanje in izterjava le-teh,',
          'priprava načrta vzdrževanja in upravljanje z rezervnim skladom na podlagi sprejetega načrta,',
          'zastopanje lastnikov v pravnem prometu,',
          'vložitev izključitvene tožbe ter tožbe za plačilo stroškov, ki bremenijo lastnika,',
          'priprava poročil'
          img: require('@/assets/icons/ISO-blue.svg')
        ] */
      }
    },
  ]
}