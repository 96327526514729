export default {
  title: 'KLJUČNI STEBRI UPRAVLJANJA',
  flash: {
    title: 'Naši stebri za vaš uspeh.',
    body: 'Naša ponudba je razdeljena v tri ključne stebre. Ti predstavljajo optimalnen splet storitev na področju upravljanja nepremičnin, ki nam omogoča soočanje z vsemi izzivi, s katerimi se srečujejo investitorji ali lastniki objektov.'
  },
  tabs: [
    {
      title: 'UPRAVLJANJE PREMOŽENJA',
      content: {
        intro: 'Upravljanje premoženja',
        body: '',
        list: [
          'Finančne storitve; sestava mesečnega obračuna stroškov, razdeljevanje in izterjava le-teh',
          'Nadzor nad izvajalci storitev',
          'Poročanje',
          'Priprava proračuna za operativne (OPEX) in investicijske (CAPEX) stroške',
          'Vodenje najemnih pogodb in administrativne storitve',
          'Usklajevanje z veljavnimi zakoni in predpisi',
          'Izdajanje najemnin v imenu in za račun lastnika',
          'Trženje neoddanih prostorov'
        ]
      }
    },
    {
      title: 'VZDRŽEVANJE',
      content: {
        intro: 'Vzdrževanje',
        body: '',
        list: [
          'Vzdrževanje tehničnih sistemov in opreme',
          'Upravljanje s sistemom upravljanja stavb',
          'Storitve čiščenja',
          'Zatiranje škodljivcev',
          'Storitve v zimskih razmerah',
          'Storitve vzdrževanja',
          'Čiščenje fasade',
          'Storitve s področja varnosti (zagotovljene s strani certificiranih podizvajalcev)',
          'Receptorske storitve',
          'Izvajanje dnevnih inšpekcij, nadzor in koordinacija'
        ]
      }
    },
    {
      title: 'Svetovanje',
      content: {
        intro: 'Svetovanje',
        body: '',
        list: [
          'Strokovno svetovanje na vseh področjih upravljanja in vzdrževanja',
          'Energetski menedžment',
          'Svetovanje na področju energijske učinkovitosti',
          'Projektni menedžment',
          'Storitve inženiringa na področju vzdrževalnih del in investicij (priprava popisov, vodenje razpisov in izvedbe del)'
        ]
      }
    }
  ]
}