export default {
  title: 'Nepremičnine v upravljanju',
  slides: [
    /* {
      img: {
        src: require('@/assets/real-estates/BTCCity-1024x.webp'),
        fb: require('@/assets/real-estates/BTCCity-1024x.jpg'),
        alt: 'Slika stolpnice BTC iz zraka'
      },
      property_name: 'Stolpnica BTC',
      location: 'Ameriška ulica 2,<br>Ljubljana',
      // years: '2000-trenutno',
      features: [
        '15.000 m2'
      ]
    }, */
    {
      img: {
        src: require('@/assets/real-estates/kristalna_palaca-1024x.webp'),
        fb: require('@/assets/real-estates/kristalna_palaca-1024x.jpg'),
        alt: 'Slika Kristalne Palače'
      },
      property_name: 'Kristalna Palača',
      location: 'Ameriška ulica 8,<br>Ljubljana',
      // years: '2011-trenutno',
      features: [
        '46.784 m2'
      ]
    },
    {
      img: {
        src: require('@/assets/real-estates/steklenidvor_kad-1024x.webp'),
        fb: require('@/assets/real-estates/steklenidvor_kad-1024x.jpg'),
        alt: 'Slika Steklenega dvora jug'
      },
      property_name: 'Stekleni Dvor',
      location: 'Dunajska cesta 117 in 119,<br>Ljubljana',
      // years: '2006-trenutno',
      features: [
        '33.821 m2'
      ]
    },
    {
      img: {
        src: require('@/assets/real-estates/center_zalog-1024x.webp'),
        fb: require('@/assets/real-estates/center_zalog-1024x.jpg'),
        alt: 'Slika centra Zalog'
      },
      property_name: 'Spar Zalog',
      location: 'Agrokombinatska 2,<br>Ljubljana',
      // years: '2016-trenutno',
      features: [
        '5.803 m2'
      ]
    },
    {
      img: {
        src: require('@/assets/real-estates/fenix-1024x.webp'),
        fb: require('@/assets/real-estates/fenix-1024x.jpg'),
        alt: 'Slika objektov Feniks iz zraka'
      },
      property_name: 'Feniks',
      location: 'Letališka cesta 29a, 29b, 29c,<br>Ljubljana',
      // years: '2000-trenutno',
      features: [
        '85.347 m2'
      ]
    },
    {
      img: {
        src: require('@/assets/real-estates/karea_durs_kranj-1024x.webp'),
        fb: require('@/assets/real-estates/karea_durs_kranj-1024x.jpg'),
        alt: 'Slika Kare A objektov'
      },
      property_name: 'Kare A',
      location: 'Koroška cesta 17, 19, 21,<br>Kranj',
      // years: '2000-trenutno',
      features: [
        '50.500 m2'
      ]
    },
    {
      img: {
        src: require('@/assets/real-estates/rezidenca-1024x.webp'),
        fb: require('@/assets/real-estates/rezidenca-1024x.jpg'),
        alt: 'Slika Rezidence'
      },
      property_name: 'Rezidenca',
      location: 'Šmartinska 53,<br>Ljubljana',
      // years: '2000-trenutno',
      features: [
        '12.521 m2'
      ]
    },
    /* {
      img: {
        src: require('@/assets/real-estates/tus-1024x.webp'),
        fb: require('@/assets/real-estates/tus-1024x.jpg'),
        alt: 'Slika stolpnice BTC iz zraka'
      },
      property_name: 'Tus',
      location: 'Ljubljana, Ameriška ulica 2',
      years: '2000-trenutno',
      features: [
        '15.000 m2'
      ]
    }, */
    {
      img: {
        src: require('@/assets/real-estates/ypsilon_deltateam-1024x.webp'),
        fb: require('@/assets/real-estates/ypsilon_deltateam-1024x.jpg'),
        alt: 'Slika objekta Y'
      },
      property_name: 'Objekt Ypsilon',
      location: 'Dimičeva 16,<br>Ljubljana',
      // years: '2009-trenutno',
      features: [
        '10.570 m2'
      ]
    },
  ]
}