export default {
  title: 'Key management pillars',
  flash: {
    title: 'Our pillars for your success.',
    body: 'Our offer is divided into three key pillars. These represent an optimal network of services in the field of real estate management, which enables us to face all challenges, faced by investors or facility owners.'
  },
  tabs: [
    {
      title: 'ASSET MANAGEMENT',
      content: {
        intro: 'Asset management',
        body: '',
        list: [
          'Financial services; preparing a monthly expenses account, its division and recovery of expense amounts',
          'Monitoring of service providers',
          'Reporting',
          'Preparing the budget for operational (OPEX) and investment (CAPEX) expenses',
          'Management of lease contracts and administrative services',
          'Ensuring compliance with relevant laws and regulations',
          'Issuance of leases for and on behalf of the owner',
          'Marketing of unleased property',
        ]
      }
    },
    {
      title: 'MAINTENANCE',
      content: {
        intro: 'Maintenance',
        body: '',
        list: [
          'Maintenance of technical systems and equipment',
          'Operating a building management system',
          'Cleaning services',
          'Pest control',
          'Winter services',
          'Property caretaker services',
          'Facade cleaning',
          'Security services (provided by certified subcontractors)',
          'Receptionist services',
          'Performing daily inspections, supervising and coordinating all services provided'
        ]
      }
    },
    {
      title: 'CONSULTANCY',
      content: {
        intro: 'Consultancy',
        body: '',
        list: [
          'Expert consulting in all management and maintenance fields',
          'Energy management',
          'Consulting in the field of energy efficiency',
          'Project management',
          'Engineering services in the fields of maintenance works and investments (preparation of inventories, management of tenders and implementation of works)'
        ]
      }
    }
  ]
}